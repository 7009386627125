<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Create Address</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-autocomplete dense clearable label="District*" :items="districts" :rules="rules.district" :error-messages="errors.district" v-model="object.district"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Block No." :error-messages="errors.block_number" v-model="object.block_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense clearable label="Street Name*" :rules="rules.street_name" :error-messages="errors.street_name" v-model="object.street_name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <v-text-field dense clearable label="Building Name" :error-messages="errors.building_name" v-model="object.building_name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-text-field dense clearable label="Postal Code*" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.postal_code"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Latitude" :rules="rules.latitude" :error-messages="errors.latitude" v-model="object.latitude"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Longitude" :rules="rules.longitude" :error-messages="errors.longitude" v-model="object.longitude"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-checkbox dense label="Create as outlet" :error-messages="errors.is_outlet" v-model="object.is_outlet"></v-checkbox>
                        </v-col>
                    </v-row>
                    <template v-if="object.is_outlet">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-autocomplete dense clearable label="Chain" :items="chains" :error-messages="errors.chain" v-model="object.chain"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Name" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Outlet Unit No." :rules="errors.unit_number" :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Person In Charge" :error-messages="errors.contact_person" v-model="object.contact_person"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Contact Number" :error-messages="errors.contact_number" v-model="object.contact_number"></v-text-field>
                        </v-col>
                    </v-row>
                    </template>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'AddressCreateDialog',
	props: {
        districts: {
            type: Array,
            required: false,
            default: () => []
        },
        chains: {
            type: Array,
            required: false,
            default: () => []
        }
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                is_outlet: '',
                block_number: '',
                street_name: '',
                building_name: '',
                postal_code: '',
                latitude: '',
                longitude: '',
                chain: '',
                name: '',
                unit_number: '',
                contact_person: '',
                contact_number: ''
			},
			rules: {
                district: [
                    (value) => !!value || 'District is required'
                ],
                street_name: [
                    (value) => !!value || 'Street name is required'
                ],
                postal_code: [
                    (value) => {
                        if(!value) {
                            return 'Postal code is required'
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Postal code must be 6 digits'
                        }

                        return true
                    }
                ],
                latitude: [
                    (value) => {
                        if(value && isNaN(value)) {
                            return 'Latitude must a numeric value'
                        }

                        return true
                    }
                ],
                longitude: [
                    (value) => {
                        if(value && isNaN(value)) {
                            return 'Longitude must a numeric value'
                        }

                        return true
                    }
                ],
                name: [
                    (value) => {
                        if(!this.object.is_outlet) {
                            return true
                        }

                        if(!value) {
                            return 'Outlet name is required'
                        }

                        if(value.length < 2 || value.length > 100) {
                            return 'Outlet name must between 2 and 100 characters'
                        }

                        return true
                    }
                ],
                unit_number: [
                    (value) => {
                        if(!this.object.is_outlet) {
                            return true
                        }

                        if(!value) {
                            return 'Outlet unit number is required'
                        }

                        return true
                    }
                ]
            },
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
            this.dialog = false
			this.loading = false
            this.object = { block_number: '', street_name: '', unit_number: '', building_name: '', postal_code: '',
                            latitude: '', longitude: '', is_outlet: false }
			this.errors = {}
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true

                const object = copyObject(this.object)

				this.$store.dispatch('epanel/address/createAddress', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        updateOutletName: function() {
            if(this.object.building_name){
                this.object.name = this.object.building_name
            }else if(this.object.block_number){
                this.object.name = this.object.block_number
            }
        }
	},
	watch: {
        "object.is_outlet": function(newValue) {
            if(!newValue) {
                this.object.chain = ''
                this.object.name = ''
                this.object.unit_number = ''
            }else{
                this.updateOutletName()
            }
        },
        "object.block_number": function() {
            this.updateOutletName()
        },
        "object.building_name": function() {
            this.updateOutletName()
        }
	}
}

</script>